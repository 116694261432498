import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    user: localStorage.getItem('yoyo'),
    isLoggedIn: !!localStorage.getItem('yoyo')
  },
  mutations: {
    user: (state, payload) => (state.user = payload),
    isLoggedIn: (state, payload) => (state.isLoggedIn = payload)
  },
  actions: {
    user(state, payload) {
      const str = btoa(
        encodeURIComponent(JSON.stringify(payload)).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1)
          }
        )
      )
      localStorage.setItem('yoyo', str)
      state.commit('user', str)
    },
    isLoggedIn: (state, payload) => state.commit('isLoggedIn', payload)
  },
  getters: {
    user(state) {
      if (typeof state.user === 'string') {
        return JSON.parse(
          decodeURIComponent(
            atob(state.user)
              .split('')
              .map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
              })
              .join('')
          )
        )
      } else {
        return null
      }
    },
    isLoggedIn: state => state.isLoggedIn
  }
})
