<template>
  <div id="app">
    <MainHeader v-if="$store.getters.isLoggedIn" />
    <AppContainer>
      <b-row>
        <b-col cols md="8" class="mr-auto ml-auto">
          <router-view :key="$route.fullPath" />
        </b-col>
      </b-row>
    </AppContainer>
  </div>
</template>

<script>
import MainHeader from "./shared/MainHeader";
const AppContainer = () => import("@/core/Components/App/AppContainer.vue");
export default {
  name: "App",
  components: {
    MainHeader,
    AppContainer
  }
};
</script>
