import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      component: () => import('./views/Movies/Dashboard.vue'),
      name: 'dashboard',
      meta: { requireAuth: true }
    },
    {
      path: '/movies',
      component: () => import('./views/Movies/Create.vue'),
      name: 'movies-create',
      meta: { requireAuth: true }
    },
    {
      path: '/thrillers',
      component: () => import('./views/Thrillers/All.vue'),
      name: 'thrillers-all',
      meta: { requireAuth: true }
    },
    {
      path: '/thrillers/create',
      component: () => import('./views/Thrillers/Create.vue'),
      name: 'thrillers-create',
      meta: { requireAuth: true }
    },
    {
      path: '/thrillers/edit/:id',
      component: () => import('./views/Thrillers/Edit.vue'),
      name: 'thrillers-edit',
      props: true,
      meta: { requireAuth: true }
    },
    {
      path: '/popups',
      component: () => import('./views/Popups/All.vue'),
      name: 'popups-all',
      meta: { requireAuth: true }
    },
    {
      path: '/popups/create',
      component: () => import('./views/Popups/Create.vue'),
      name: 'popups-create',
      meta: { requireAuth: true }
    },
    {
      path: '/popups/edit/:id',
      component: () => import('./views/Popups/Edit.vue'),
      name: 'popups-edit',
      props: true,
      meta: { requireAuth: true }
    },
    {
      path: '/tickets',
      component: () => import('./views/Tickets/All.vue'),
      name: 'tickets-all',
      meta: { requireAuth: true }
    },
    {
      path: '/tickets/create',
      component: () => import('./views/Tickets/Create.vue'),
      name: 'tickets-create',
      meta: { requireAuth: true }
    },
    {
      path: '/tickets/edit/:id',
      component: () => import('./views/Tickets/Edit.vue'),
      name: 'tickets-edit',
      props: true,
      meta: { requireAuth: true }
    },
    {
      path: '/upcomings',
      component: () => import('./views/Upcomings/All.vue'),
      name: 'upcomings-all',
      meta: { requireAuth: true }
    },
    {
      path: '/upcomings/create',
      component: () => import('./views/Upcomings/Create.vue'),
      name: 'upcomings-create',
      meta: { requireAuth: true }
    },
    {
      path: '/upcomings/edit/:id',
      component: () => import('./views/Upcomings/Edit.vue'),
      name: 'upcomings-edit',
      props: true,
      meta: { requireAuth: true }
    },
    {
      path: '/movies/edit/:id',
      component: () => import('./views/Movies/Edit.vue'),
      name: 'movies-edit',
      props: true,
      meta: { requireAuth: true }
    },
    {
      path: '/shows',
      component: () => import('./views/Movies/Shows.vue'),
      name: 'shows',
      meta: { requireAuth: true }
    },
    {
      path: '/login',
      component: () => import('./views/Auth/Login.vue'),
      name: 'login',
      meta: { requireVisitor: true }
    }
  ]
})
