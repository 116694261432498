<template>
  <AppNavbar>
    <AppContainer>
      <AppBrand />
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <AppNavCollapse>
        <AppNavbarNav />
      </AppNavCollapse>
    </AppContainer>
  </AppNavbar>
</template>

<script>
const AppBrand = () => import('@/core/Components/App/AppBrand.vue')
const AppNavbar = () => import('@/core/Components/App/AppNavbar.vue')
const AppNavbarNav = () => import('@/core/Components/App/AppNavbarNav.vue')
const AppContainer = () => import('@/core/Components/App/AppContainer.vue')
const AppNavCollapse = () => import('@/core/Components/App/AppNavCollapse.vue')
export default {
  components: {
    AppBrand,
    AppNavbar,
    AppNavbarNav,
    AppContainer,
    AppNavCollapse
  }
}
</script>
