import Vue from 'vue'
import VueResource from 'vue-resource'
import { store } from './store'

Vue.use(VueResource)
Vue.config.productionTip = false
// Vue.http.options.root =
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost/api.brands/'
//     : 'https://api.brandscinemas.com/'
Vue.http.options.root = 'https://api.brandscinemas.com/'
Vue.http.interceptors.push((request, next) => {
  request.headers.set('content-type', 'application/x-www-form-urlencoded')
  next()
})

class ApiResource {
  create(url, params = {}) {
    params.token = store.getters.isLoggedIn ? store.getters.user.token : ''
    params = JSON.stringify(params)
    return Vue.http.post(url, params).then(res => res.body)
  }
}

export const api = new ApiResource()
export const BASE_ROOT = Vue.http.options.root
